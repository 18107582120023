import { grey, blueGrey } from '@material-ui/core/colors';
import { createMuiTheme } from '@material-ui/core/styles';

export const BACKGROUND = blueGrey[50];
export const OFFWHITE = grey[50];
const BORDER = grey[300];
const GUTTER = 24;

export default createMuiTheme({
  typography: {
    fontFamily: 'Nunito',
  },
  overrides: {
    MuiTypography: {
      h5: {
        fontWeight: 800,
      },
    },
    MuiBox: {
      root: {
        padding: 0,
      },
    },
    MuiFormLabel: {
      root: {
        width: '100%',
        display: 'block',
        textAlign: 'left',
        background: BACKGROUND,
        padding: `16px ${GUTTER}px`,
        boxSizing: 'border-box',
      },
    },
    MuiFormGroup: {
      root: {
        width: '100%',
      },
    },
    MuiFormControl: {
      root: {
        boxSizing: 'border-box',
      },
    },
    MuiFormControlLabel: {
      root: {
        borderTop: `1px solid ${BORDER}`,
        '&:last-child': {
          borderBottom: `1px solid ${BORDER}`,
        },
        padding: `8px 8px 8px ${GUTTER}px`,
      },
      label: {
        marginLeft: 0,
        marginRight: 'auto',
      },
      labelPlacementStart: {
        marginRight: 0,
        marginLeft: 0,
      },
    },
  },
});
