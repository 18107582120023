import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormGroup from '@material-ui/core/FormGroup';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import { useFormikContext, getIn } from 'formik';
import getSymbolFromCurrency from 'currency-symbol-map';
import { useEffect } from 'react';
import NumericInput from './components/primitives/NumericInput';
import { OFFWHITE } from './theme';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  fullWidth: {
    width: '100%',
  },
}));

function build_row_column(row_element, currencyFormat, config) {
  const item_type = row_element.type;
  switch (true) {
    case item_type === 'price':
      return currencyFormat.format( row_element.value || '');
    case item_type === 'string':
      return row_element.value || '';
    case item_type === 'defaultNumber':
      return row_element.value || '';
    default:
      return;
  }
}

function build_row(row, currencyFormat, config) {
  let built_row = '';
  for (let i = 0; i < row.row_data.length; i++) {
    let row_part = build_row_column(row.row_data[i], currencyFormat, config);
    built_row = built_row + ' ' + row_part;
  }
  return built_row;
}

export default function Collection({ config, collectionIndex, collectionData }) {
  const { values, setFieldValue, setFieldError } = useFormikContext();
  const name = `collections[${collectionIndex}]`;
  const collection_config = config.collections[collectionIndex];
  const columns_config = collection_config.columns;

  const classes = useStyles();

  useEffect(() => {});

  const currencyFormat = useMemo(
    // TODO: use actual user locale instead of assuming en-US
    () => new Intl.NumberFormat(navigator.language , { style: 'currency', minimumFractionDigits: 0, currency: config.currency || 'USD' }),
    [config.currency]
  );

  return (
    <div className={classes.root}>
      {collection_config.select_type == 'radio' ? (
        <RadioGroup
          defaultValue={
            // If there is no value at the default selection index, use the first
            collectionData[collection_config.select_default] == undefined ? (
              build_row(collectionData[0], currencyFormat, config)
            ):(
              build_row(collectionData[collection_config.select_default], currencyFormat, config)
            )
            
          }
        >
          {collectionData?.map((row, index) =>
            row[columns_config[0].column_name] == '' ? (
              <></>
            ) : (
              <FormControlLabel
                style={{ background: OFFWHITE }}
                label={build_row(row, currencyFormat, config)}
                labelPlacement="start"
                value={build_row(row, currencyFormat, config)}
                key={index}
                control={
                  <Radio
                    id={index + ''}
                    onChange={(e) => {
                      const checkedList = new Array(getIn(values, name).length);
                      checkedList.fill(false);
                      checkedList[index] = e.target.checked;
                      setFieldValue(name, checkedList);
                    }}
                    color="primary"
                  />
                }
              />
            )
          )}
        </RadioGroup>
      ) : collection_config.select_type === 'checkbox' ? (
        <FormGroup style={{ width: '100%' }}>
          {collectionData?.map((row, index) =>
            row[columns_config[0].column_name] == '' ? (
              <></>
            ) : (
              <FormControlLabel
                style={{ background: OFFWHITE }}
                key={index}
                label={build_row(row, currencyFormat, config)}
                labelPlacement="start"
                control={
                  <Checkbox
                    id={index + ''}
                    onChange={(e) => {
                      const checkedList = [...getIn(values, name)];
                      checkedList[index] = e.target.checked;
                      setFieldValue(name, checkedList);

                      const numChecked = checkedList.filter((value) => value).length;
                      const { select_min, select_max } = collection_config;
                      if (select_min && numChecked < select_min) {
                        setFieldError(name, `Select at least ${select_min}`);
                      } else if (select_max && numChecked > select_max) {
                        setFieldError(name, `Select at most ${select_max}`);
                      } else {
                        setFieldError(name, undefined);
                      }
                    }}
                    //name={row_data.item}
                    color="primary"
                  />
                }
              />
            )
          )}
        </FormGroup>
      ) : (
        <FormGroup style={{ width: '100%'}}>
          {collectionData?.map((row, index) =>
            row[columns_config[0].column_name] == '' ? (
              <></>
            ) : (
              <FormControlLabel
                style={{ background: OFFWHITE }}
                key={index}
                label={build_row(row, currencyFormat, config)}
                labelPlacement="start"
                control={
                  <NumericInput
                    id={index + ''}
                    value={getIn(values, name)[index]}
                    onChange={({ value }) => {
                      const counts = [...getIn(values, name)];
                      counts[index] = value;
                      setFieldValue(name, counts);

                      const totalCount = counts.reduce((a, b) => a + b, 0);
                      const { select_min, select_max } = collection_config;
                      if (select_min && totalCount < select_min) {
                        setFieldError(name, `Select at least ${select_min}`);
                      } else if (select_max && totalCount > select_max) {
                        setFieldError(name, `Select at most ${select_max}`);
                      } else {
                        setFieldError(name, undefined);
                      }
                    }}
                    //name={row_data.item}
                    color="primary"
                  />
                }
              />
            )
          )}
        </FormGroup>
      )}
    </div>
  );
}
