import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import { makeStyles, Box } from '@material-ui/core';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  value: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '32px',
  },
});

function Control({ value, onChange, diff, Icon }) {
  return (
    <IconButton onClick={() => onChange({ value: value + diff })} disabled={value + diff < 0}>
      <Icon />
    </IconButton>
  );
}

export default function NumericInput({ value, onChange }) {
  const css = useStyles();

  return (
    <div class={css.container}>
      <Control Icon={RemoveCircleOutlineIcon} diff={-1} value={value} onChange={onChange} />
      <div className={css.value}>{value}</div>
      <Control Icon={AddCircleOutlineIcon} diff={+1} value={value} onChange={onChange} />
    </div>
  );
}
