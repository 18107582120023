export class ApiError extends Error {
  constructor(details) {
    super();
    this.details = details;
    this.message = this.toString();
  }

  toString() {
    const { url, request } = this.details;
    return `HTTP ${request.status} from ${url}:
${request.responseText}`;
  }
}

export default async function postJson(url, data, contentType = undefined) {
  /* eslint-disable */
  const request = window.XMLHttpRequest
    ? new XMLHttpRequest()
    : new ActiveXObject('Microsoft.XMLHTTP');

  return new Promise((accept, reject) => {
    request.onreadystatechange = function () {
      if (this.readyState !== 4) return;

      if (this.status !== 200) {
        reject(new ApiError({ url, request: this }));
        return;
      }
      accept(JSON.parse(this.responseText));
    };
    request.open('POST', url, true);
    if (contentType !== undefined) request.setRequestHeader('Content-Type', contentType);
    request.send(JSON.stringify(data));
  });
}
