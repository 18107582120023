import React, { useCallback } from 'react';
import { makeStyles, ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import axios from 'axios';
import GenericWebview from './GenericWebview';
import postJson from './api';

export default function Webview({
  config,
  config_type,
  webview_key,
  userID,
  usertime,
  webviewData,
  setErrorMessage,
  setErroredOut,
}) {
  const sendFlow = useCallback(
    async (flow) => {
      const body_data = {
        method: 'sendFlow',
        'webview-key': webview_key,
        'user-id': userID,
        flow: flow
      };
      try {
        const response = await postJson(
          'https://api.botsheets.com/dashboard/api',
          body_data
        );
        if (response.status === 200) {
          return { success: true };
        }
        throw new Error();
      } catch {
        return { success: false };
      }
    },
    [userID, webview_key]
  );

  const setManychatFields = useCallback(
    async (user_fields) => {
      const body_data = {
        'user-fields': JSON.stringify(user_fields),
        method: 'setMCField',
        'webview-key': webview_key,
        'user-id': userID,
      };
      try {
        const response = await postJson(
          'https://api.botsheets.com/dashboard/api',
          body_data
        );
        console.log(response)
        if (response.success) {
          return { success: true };
        }
        throw new Error();
      } catch(err) {
        console.log(err)
        return { success: false };
      }
    },
    [userID, webview_key]
  );

  const expireWebview = useCallback(async () => {
    const headers = {
      headers: {
        method: 'setWebviewExpired',
        usertime: usertime,
        Accept: '*/*',
        'Access-Control-Allow-Origin': 'http://localhost:3000',
        'Access-Control-Allow-Credentials': 'true',
      },
    };
    try {
      let data = {};
      let response = await axios.post('https://api.botsheets.com/dashboard/api', data, headers);
      if (response.status === 200) {
        return { success: true };
      }
      throw new Error();
    } catch {
      return { success: false };
    }
  }, [usertime]);

  switch (config.type) {
    case 'generic':
      return (
        <GenericWebview
          webviewData={webviewData}
          config={config}
          setManychatFields={setManychatFields}
          sendFlow={sendFlow}
          expireWebview={expireWebview}
          setErrorMessage={setErrorMessage}
          setErroredOut={setErroredOut}
        ></GenericWebview>
      );
    default:
      return <></>;
  }
}
