import { Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import axios from 'axios';
import axiosRetry from 'axios-retry';
import * as qs from 'query-string';
import React from 'react';
import postJson from './api';
import './App.css';
import useMessengerExtensions from './messengerExtensions';
import theme from './theme';
import Webview from './Webview';

axios.defaults.withCredentials = false;
axiosRetry(axios, { retries: 3 });

const messengerBlue = {
  light: '#0078FF',
  main: '#0078FF',
  dark: '#0078FF',
  contrastText: '#0078FF',
};

const theme2 = createMuiTheme((theme) => ({
  palette: {
    ...theme.palette,
    primary: messengerBlue,
  },
}));

async function botsheetsTextRequest(bs_key, { parsed }) {
  try {
    const body_data = {
      'x-api-key': bs_key,
      query: parsed['query'] || '',
      searchType: 'json',
      pageName: parsed['page-name'],
      sheetName: parsed['sheet-name'],
      googleSheetID: parsed['sheet-id'],
    };
    const response = await postJson(
      'https://api.botsheets.com/botsheets-text/text-proxy',
      body_data,
      'application/json;charset=UTF-8'
    );
    const data = [...response.body];
    if(response.statusCode == 400){
      return {
        success: false,
        message:data.join("") + " If you think you have not used all of your requests, you may need to recreate your webview token!"
      };
    }
    else if (data.length < 1 ) {
      console.log(response);
      return {
        success: false,
        message:
          'No data found with this query: ' +
          parsed['query'] +
          ' in the worksheet named: "' +
          parsed['sheet-name'] +
          '"',
      };
    } else {
      console.log('ALL GOOD');
      return { success: true, data };
    }
  } catch (error) {
    return {
      success: false,
      message: error,
    };
  }

}

async function getConfig({ configName, webviewToken, setConfig }) {
  try {
    const response = await postJson('https://api.botsheets.com/dashboard/api', {
      method: 'getConfig',
      'config-name': configName,
      'webview-token': webviewToken,
    });
    let _config = response['config-json'];
    setConfig(_config);
    console.log(_config);
    return { success: true, config: _config };
  } catch (error) {
    return {
      success: false,
      message:
        'NO configuration found for configuration named: ' +
        configName +
        '. Please check that you have a configuration named ' +
        configName +
        '. Also, please check that you have added your webview key as a bot field.'
    };
  }
}

async function checkExpiredWebview({ userID, time, skipExpiryCheckUrlParamPresent, expiryUrlParam }) {
  if (skipExpiryCheckUrlParamPresent) {
    if (expiryUrlParam == "true" || expiryUrlParam == "True") {
      return {
        success: true,
        isExpired: false,
      };
    }
  }
  try {
    const response = await postJson('https://api.botsheets.com/dashboard/api', {
      method: 'checkWebviewExpired',
      usertime: userID + '-' + time,
    });
    const isExpired = await response['isExpired'];
    console.log(response.data);
    return { success: true, isExpired };
  } catch {
    return {
      success: false,
      message: 'Internal error with this userID ' + userID + ' and this time: ' + time,
    };
  }
}

async function getBSKey({ webviewToken }) {
  try {
    const response = await postJson('https://api.botsheets.com/dashboard/api', {
      method: 'getBSKey',
      'webview-token': webviewToken,
    });
    return { success: true, key: response['bs-key'] };
  } catch (err) {
    return {
      success: false,
      message: 'NO Botsheets Key found for this Webview Token : ' + webviewToken + ' ' + err,
    };
  }
}

function checkAndTrimData(reformatted_data, config, sheet_name) {
  console.log(reformatted_data);

  for (let collection_i = 0; collection_i < config.collections.length; collection_i++) {
    let trimmed_rows = [];
    for (let row_i = 0; row_i < reformatted_data[collection_i].length; row_i++) {
      let row = reformatted_data[collection_i][row_i];
      if (row.row_data.length === config.collections[collection_i].columns.length) {
        console.log(`pushing this row: ${JSON.stringify(row)}`);
        trimmed_rows.push(row);
      } else {
        console.warn(`Warning: rows were trimmed: ${JSON.stringify(row)}`);
      }
    }
    reformatted_data[collection_i] = trimmed_rows;

    if (reformatted_data[collection_i].length < 1) {
      throw new Error(
        'No data found for the Selection named "' +
          config.collections[collection_i].title +
          '" in these columns: ' +
          config.collections[collection_i].columns.map(
            (column) => '"' + column.column_name + '" '
          ) +
          ' in the sheet named "' +
          sheet_name +
          '".' +
          '\nCheck that those columns exist in the "' +
          sheet_name +
          '" sheet.'
      );
    }
  }
}

function reformat(data, config, sheet_name) {
  let collection_data = [];
  data =  data.filter((data_row) => data_row.active!="FALSE" )
  for (let collection_i = 0; collection_i < config.collections.length; collection_i++) {
    collection_data[collection_i] = [];
    let collection = config.collections[collection_i];
    for (let column_i = 0; column_i < collection.columns.length; column_i++) {
      let column_name = collection.columns[column_i].column_name;
      let type = collection.columns[column_i].type;
      let row_num = 0;
      for (let sheet_row = 0; sheet_row < data.length; sheet_row++) {
        let value = data[sheet_row][column_name] || undefined;
        if (value === undefined || value == '') {
          row_num += 1;
          continue;
        }
        //initialize the row if it's not already
        if (collection_data[collection_i][row_num] == undefined) {
          collection_data[collection_i][row_num] = { row_data: [] };
        }
        //Add another column to the row's row_data
        collection_data[collection_i][row_num].row_data.push({
          value: value,
          type: type,
        });
        row_num += 1;
      }
    }
    //Remove empty rows from collection
    collection_data[collection_i] = collection_data[collection_i].filter(
      (value) => Object.keys(value).length !== 0
    );
  }

  checkAndTrimData(collection_data, config, sheet_name);

  console.log('COLLECTION DATA');
  console.log(collection_data);

  // Set radio defaults. This needs to happen after the trim.
  for (let collection_i = 0; collection_i < collection_data.length; collection_i++) {
    const collection = config.collections[collection_i];
    if (collection.select_type === 'radio') {
      // If there is no value at the default selection index, use the first
      if (collection_data[collection_i][collection.select_default] == undefined){
        collection_data[collection_i][0]._checked = true;
      }
      else {
        collection_data[collection_i][collection.select_default]._checked = true;
      }
        
    } else if (collection.select_type === 'numeric') {
      for (const item of collection_data[collection_i]) {
        item._count = 0;
      }
    }
  }

  console.log(collection_data);

  return collection_data;
}

function App() {
  const [webviewData, setWebviewData] = React.useState(undefined);
  const [isExpired, setIsExpired] = React.useState(false);
  const [config, setConfig] = React.useState();
  const [errorMessage, setErrorMessage] = React.useState(undefined);
  const MessengerExtensions = useMessengerExtensions();
  const erroredOut = errorMessage !== undefined;

  const parsed = qs.parse(window.location.search);
  const userID = parsed['user-id'];
  const time = parsed['time'];
  const configName = parsed['config-name'];
  const webviewToken = parsed['webview-token'];

  const isLoading = !isExpired && (!MessengerExtensions || !(webviewData || erroredOut));

  React.useEffect(
    () => {
      // Wait for messenger extensions to be loaded before making
      // any requests.
      if (!MessengerExtensions) return;

      (async () => {
        const [configResponse, bsResponse, expiredResponse] = await Promise.all([
          getConfig({ configName, webviewToken, setConfig }),
          getBSKey({ webviewToken }),
          checkExpiredWebview({
            userID,
            time,
            skipExpiryCheckUrlParamPresent: parsed['noexpire'] !== undefined,
            expiryUrlParam: parsed['noexpire']
          }),
        ]);
        if (!configResponse.success || !bsResponse.success || !expiredResponse.success) {
          setErrorMessage(configResponse.message || bsResponse.message || expiredResponse.message);
          return;
        }
        setIsExpired(expiredResponse.isExpired);
        const response = await botsheetsTextRequest(bsResponse.key, {
          parsed,
        });

        if (!response.success) {
          setErrorMessage(response.message);
          return;
        }
        let reformatted;
        try {
          reformatted = reformat(response.data, configResponse.config, parsed['sheet-name']);
        } catch (error) {
          setErrorMessage(error + '');
          return;
        }
        setWebviewData(reformatted);
      })();
    },
    // eslint-disable-next-line
    [MessengerExtensions]
  );

  return (
    <ThemeProvider theme={theme} style={{ display: 'block' }}>
      <div className="App">
        {(() => {
          switch (true) {
            case isLoading:
              return (
                <div style={{ textAlign: 'center' }}>
                  <ThemeProvider theme={theme2}>
                    <CircularProgress color="primary"></CircularProgress>
                  </ThemeProvider>
                </div>
              );
            case isExpired:
              return (
                <ThemeProvider theme={theme2}>
                  <Typography color="primary">This form is expired</Typography>
                </ThemeProvider>
              );
            case !isLoading && !erroredOut:
              return (
                <Webview
                  webviewData={webviewData}
                  userID={userID}
                  usertime={userID + '-' + time}
                  config={config}
                  webview_key={webviewToken}
                  setErrorMessage={setErrorMessage}
                ></Webview>
              );
            case erroredOut:
              return <>{errorMessage}</>;
            default:
              return null;
          }
        })()}
      </div>
    </ThemeProvider>
  );
}

export default App;
