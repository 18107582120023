import React, { useMemo } from 'react';
import { makeStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Collection from './Collection';
import Button from '@material-ui/core/Button';
import FormLabel from '@material-ui/core/FormLabel';
import { Typography, Box } from '@material-ui/core';
import useMessengerExtensions from './messengerExtensions';
import { BACKGROUND } from './theme';
import { Formik, Form, getIn, useFormikContext } from 'formik';
import JsonDebug from './JsonDebug';

const messengerBlue = {
  light: '#0078FF',
  main: '#0078FF',
  dark: '#0078FF',
  contrastText: '#0078FF',
};

const white = {
  light: '#ffffff',
  main: '#ffffff',
  dark: '#ffffff',
  contrastText: '#ffffff',
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    paddingBottom: '116px',
    minHeight: '100%',
  },
  form: {
    height: '100%',
    position: 'relative',
  },
  stickyFooter: {
    position: 'sticky',
    background: 'white',
    bottom: 0,
    boxShadow: '0 -1px 3px rgba(0, 0, 0, 0.12), 0 -2px 12px rgba(0, 0, 0, 0.05)',
  },
  formControl: {
    width: '100%',
  },
  fullWidth: {
    width: '100%',
  },
  subtotalRow: {
    padding: '24px 24px 0',
    display: 'flex',
    justifyContent: 'space-between',
  },
  subtle: {
    color: 'grey',
  },
}));

/**
 * Returns the Manychat fields to set based on the webview config and form values.
 */
function getFieldsToSet(sheet_data, values, config) {
  const fieldsToSet = [];
  let collectionIndex = 0;
  for (const collection of config.collections) {
    const fields = [];
    const form_collection_data = values.collections[collectionIndex];
    const sheet_collection_data = sheet_data[collectionIndex];
    let columnIndex = 0;
    for (const columns_config of collection.columns) {
      const value = getFieldValues(
        sheet_collection_data,
        form_collection_data,
        collectionIndex,
        columnIndex,
        columns_config
      );
      fields.push({
        field_name: columns_config.mc_field,
        field_type: columns_config.type,
        field_value: value,
      });
      columnIndex++;
    }
    fieldsToSet.push(fields);
    collectionIndex++;
  }
  return fieldsToSet;
}

function getFieldValues(
  sheet_collection_data,
  form_collection_data,
  collectionIndex,
  columnIndex,
  columns_config
) {
  if (columns_config.mc_field != 'undefined') {
    if (columns_config.join_type == 'delimiter') {
      const value = sheet_collection_data
        .filter((row, index) => form_collection_data[index])
        .map((row) => row.row_data[columnIndex].value)
        .join(columns_config.join_with);
      return value;
    } else if (columns_config.join_type == 'add') {
      const value = sheet_collection_data
        .filter((row, index) => form_collection_data[index])
        .reduce((total, row) => total + parseFloat(row.row_data[columnIndex].value), 0);
      return value;
    }
  }
}

function closeWindow({ MessengerExtensions, config, sendFlow, setErrorMessage }) {
  sendFlow(config.submit.button.target).then(()=>{
    try {
      MessengerExtensions.requestCloseBrowser(
        function success() {
          //setErrorMessage('It thinks it worked');
        },
        function error(err) {
          setErrorMessage(<Typography align="center" variant="h6" color="primary">Thank you, you may now close this window.</Typography>);
        }
      );
    } catch {
      //setErrorMessage('ERROR on running closebrowser');
    }
    }
  )
}

function CollectionRequirements({ collection, hasError }) {
  if (hasError) {
    return <span style={{ color: 'red' }}>{hasError}</span>;
  }

  if (collection.select_type === 'radio') {
    return <></>;
  }
  /*
  const { select_min, select_max } = collection;

  if (select_min && select_max && select_min < select_max) {
    return (
      <>
        Choose between {select_min} and {select_max}
      </>
    );
  }
  if (!select_min && select_max) {
    return <>Choose at most {select_max}</>;
  }
  if (select_min && !select_max) {
    return <>Choose at least {select_min}</>;
  }
  */
  return <></>;
}

const validate = (values, config) => {
  for (const [i, collection] of Object.entries(config.collections)) {
    if (collection.select_type === 'checkbox') {
      const numChecked = values.collections[i].filter((checked) => checked).length;
      const { select_min, select_max } = collection;
      if (select_min && numChecked < select_min) {
        return { collections: { [i]: ` *` } };
        //return { collections: { [i]: `Select at least ${select_min}` } };
      }
      if (select_max && numChecked > select_max) {
        return { collections: { [i]: ` ⚠️` } };
        //return { collections: { [i]: `Select at most ${select_max}` } };
      }
    } else if (collection.select_type === 'numeric') {
      const totalCount = values.collections[i].reduce((a, b) => a + b, 0);
      const { select_min, select_max } = collection;
      if (select_min && totalCount < select_min) {
        return { collections: { [i]: ` *` } };
        //return { collections: { [i]: `Select at least ${select_min}` } };
      }
      if (select_max && totalCount > select_max) {
        return { collections: { [i]: ` ⚠️` } };
        //return { collections: { [i]: `Select at most ${select_max}` } };
      }
    }
  }
};

export default function GenericWebview({
  config,
  webviewData,
  sendFlow,
  expireWebview,
  setManychatFields,
  setErrorMessage,
}) {
  const MessengerExtensions = useMessengerExtensions();

  const themeColor = config?.theme?.color || messengerBlue;

  const theme = useMemo(
    () => (theme) =>
      createMuiTheme({
        ...theme,
        palette: {
          ...theme.palette,
          primary: themeColor,
          secondary: white,
        },
      }),
    [themeColor]
  );

  const classes = useStyles();

  function submit(values, { setSubmitting }) {
    const flattened_fields = [];
    for (const list of getFieldsToSet(webviewData, values, config)) {
      for (const field of list) {
        flattened_fields.push(field);
      }
    }
    console.log(flattened_fields);
    setManychatFields(flattened_fields).then(() => {
      expireWebview();
      closeWindow({
        MessengerExtensions,
        config,
        sendFlow,
        setErrorMessage,
      });
    });
  }

  const initialValues = useMemo(
    () => ({
      collections: webviewData.map((collection) =>
        collection.map((item) => {
          if ('_checked' in item) {
            return item._checked;
          }
          if ('_count' in item) {
            return item._count;
          }
          return false;
        })
      ),
    }),
    [webviewData]
  );

  return (
    <ThemeProvider theme={theme}>
      <Formik
        onSubmit={submit}
        initialValues={initialValues}
        validateOnChange={true}
        validateOnMount={true}
        validate={(values) => validate(values, config)}
      >
        {({ values, errors, isValid, isSubmitting }) =>
          isSubmitting ? (
            <Backdrop className={classes.backdrop} open={isSubmitting}>
              <CircularProgress color="inherit" />
            </Backdrop>
          ) : (
            <Form className={`${classes.fullWidth} ${classes.form}`}>
              {/*<JsonDebug title="formik state" object={{ isValid, values, errors }} />*/}
              <div className={classes.content}>
                <Box style={{ background: themeColor.main, padding: 16 }}>
                  <Typography align="center" variant="h6" color="secondary">
                    {config.title}
                  </Typography>
                </Box>
                {/* Have a loop for all collections */}
                {config.collections.map((collection, i) => (
                  <Box key={i} padding="8px">
                    <Box
                      style={{
                        background: BACKGROUND,
                        textAlign: 'left',
                        padding: '16px 24px',
                      }}
                    >
                      <Typography
                        variant="h6"
                        style={{
                          color: themeColor.main,
                        }}
                      >
                        {collection.title}
                        <CollectionRequirements
                          hasError={getIn(errors, `collections[${i}]`)}
                          collection={collection}
                        />
                      </Typography>
                    </Box>
                    <Collection
                      submit={submit}
                      collectionData={webviewData[i]}
                      config={config}
                      collectionIndex={i}
                    />
                  </Box>
                ))}
              </div>
              <Footer config={config} webviewData={webviewData} />
            </Form>
          )
        }
      </Formik>
    </ThemeProvider>
  );
}

function getPrice(row) {
  for (const data of row.row_data) {
    if (data.type === 'price') {
      return Number(data.value);
    }
  }
  return null;
}

function hasPriceInAnyRow(webviewData) {
  for (const collection of webviewData) {
    for (const row of collection) {
      if (getPrice(row) !== null) {
        return true;
      }
    }
  }
  return false;
}

function computeTotal(values, webviewData) {
  let total = 0;

  for (let i = 0; i < webviewData.length; i++) {
    const collection = webviewData[i];
    const collectionFormValues = values.collections[i];

    for (let j = 0; j < collection.length; j++) {
      const row = collection[j];
      const price = getPrice(row);

      if (price === null) continue;

      // Collection form values can be a boolean or
      // a number for each row. Either way, casting to Number
      // gives the quantity we are looking for.
      const quantitySelected = Number(collectionFormValues[j]);
      total += quantitySelected * price;
    }
  }

  return total;
}

function Footer({ config, webviewData }) {
  // TODO: hasPrice should be based on whether any price cols are configured
  const hasPrice = hasPriceInAnyRow(webviewData);
  const currencyFormat = useMemo(
    // TODO: use actual user locale instead of assuming en-US
    () => new Intl.NumberFormat(navigator.language, { style: 'currency', minimumFractionDigits: 0, currency: config.currency || 'USD' }),
    [config.currency]
  );
  const { isValid, values } = useFormikContext();

  const formattedTotal = hasPrice ? currencyFormat.format(computeTotal(values, webviewData)) : null;

  const subtotalDisplay = config.submit.button.subtotal_display || 'button';

  const css = useStyles();

  return (
    <div className={css.stickyFooter}>
      {hasPrice && subtotalDisplay === 'above_button' ? (
        <div className={css.subtotalRow}>
          <div className={css.subtle}></div>
          <div>{formattedTotal}</div>
        </div>
      ) : (
        ''
      )}
      <div
        style={{
          padding: 24,
          boxSizing: 'border-box',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch',
        }}
      >
        <Button
          variant="contained"
          color="primary"
          disabled={!isValid}
          label="Submit"
          type="submit"
          style={{ textTransform: 'none', minHeight: '45px', fontSize: '15px' }}
        >
          {config.submit.button.caption}
          {hasPrice && subtotalDisplay === 'button' ? ` (${formattedTotal})` : ''}{/*{isValid ? '':" ⚠️"}*/}
        </Button>
      </div>
    </div>
  );
}
